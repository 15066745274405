import React from "react"
import Headerjawn from "../components/headerjawn"
import Layout from "../components/layout"
import Footerjawn from "../components/footerjawn"

import posed from "react-pose"
import { TransitionState } from "gatsby-plugin-transition-link"
import { tween } from "popmotion";

const Box = posed.div({
  hidden: { 
    opacity: [0,1], 
    left: '-100%',
    transition: props => tween({ ...props, duration: 0 })
  },
  visible: { 
    opacity: [1,0], 
    left: '100%',
    transition: props => tween({ ...props, duration: 1000 })
  },
})



export default () => (
  <>
    <Headerjawn/> 

    <Layout>
      <section className="about-cc">
      <h2>ABout</h2> 
      <p>ABOTUWhat a world.</p>

      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
      when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
      It has survived not only five centuries, but also the leap into electronic typesetting, 
      remaining essentially unchanged. It was popularised in the 1960s with the release of 
      Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing 
      software like Aldus PageMaker including versions of Lorem Ipsum.</p>
      </section>
    </Layout>

    <Footerjawn/>

    <TransitionState>
        {({ transitionStatus }) => {
            return (
                <Box
                  className="box"
                  pose={
                    ['entering', 'entered'].includes(transitionStatus)
                      ? 'hidden'
                      : 'visible'
                  }
                />
            )
        }}
    </TransitionState>
    <style
          dangerouslySetInnerHTML={{
            __html: `
            .box {
                background: #dcf23e;
            }
        `,
          }}
    />
    
  </>
)